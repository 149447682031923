import React, { useState } from "react";
import { Grid, Row, Bead, Separator } from "./AbacusStyled";

const AbacusRow = ({ dot, value, setValue }) => {
  value = value < 0 || value > 9 ? 0 : value;

  const numbers = {
    0: [1, 0, 0, 1, 1, 1, 1],
    1: [1, 0, 1, 0, 1, 1, 1],
    2: [1, 0, 1, 1, 0, 1, 1],
    3: [1, 0, 1, 1, 1, 0, 1],
    4: [1, 0, 1, 1, 1, 1, 0],
    5: [0, 1, 0, 1, 1, 1, 1],
    6: [0, 1, 1, 0, 1, 1, 1],
    7: [0, 1, 1, 1, 0, 1, 1],
    8: [0, 1, 1, 1, 1, 0, 1],
    9: [0, 1, 1, 1, 1, 1, 0],
  };

  const add1 = [5, 0, 0, 1, 2, 3, 4];
  const add2 = [0, 0, 5, 6, 7, 8, 9];

  const set = (type, key) => {
    if (!type) {
      return;
    }

    if (key === 0) {
      return setValue(value + 5);
    } else if (key === 1) {
      return setValue(value - 5);
    }

    if (value >= 5) {
      return setValue(add2[key]);
    } else {
      return setValue(add1[key]);
    }
  };

  return (
    <Row>
      {numbers[value].map((type, key) => (
        <div key={key}>
          <Bead type={type} onClick={() => set(type, key)} />
          {key === 1 && <Separator dot={dot} />}
        </div>
      ))}
    </Row>
  );
};

const Abacus = () => {
  const size = 17;
  const defaultRows = Array(size).fill(0);
  const [rows, setRows] = useState(defaultRows);

  const handleSetValue = (value, key) => {
    let tmp = [...rows];
    tmp[key] = value;
    setRows(tmp);
  };

  return (
    <>
      <Grid cols={`repeat(${size}, 1fr)`}>
        {rows.map((row, key) => (
          <AbacusRow
            key={key}
            dot={key % 3 === 2}
            value={row}
            setValue={(value) => handleSetValue(value, key)}
          />
        ))}
      </Grid>
    </>
  );
};

export default Abacus;
