import React from "react";
import "./App.css";

import Abacus from "./Abacus";

function App() {
  return <Abacus />;
}

export default App;
